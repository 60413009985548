import { Component, OnInit, Inject } from '@angular/core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/providers/auth.service';
import { FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/providers/util.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { utils } from 'protractor';

@Component({
  selector: 'app-meu-perfil',
  templateUrl: './meu-perfil.component.html',
  styleUrls: ['./meu-perfil.component.scss']
})
export class MeuPerfilComponent implements OnInit {

  faCamera = faCamera;
  user = new Object();

  constructor(
    private auth: AuthService,
    private util: UtilService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.user = this.auth.getDataUser();

  }

  updateProfile(form: FormGroup) {

    if (!form.valid) {
      return false;
    }

    const values = form.value;

    this.auth.updateUser(values).subscribe(
      data => {
        if (data['Status'] === 'success') {
          this.util.callAlert('Ok', 'Perfil atualizado com sucesso!', data['Status']);
        } else {
          this.util.callAlert('Ops!', 'Ocorreu um erro!', data['Status']);
        }
      }
    );

  }

  openModalSenha() {

    const dialogRef = this.dialog.open(
      ModalSenhaComponent, { panelClass: 'dialog', width: '570px', data: { } }
    );

  }

  changePicture(e) {

    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.user['Imagem'] = reader.result;
  }

}


@Component({
  selector: 'modal-senha',
  templateUrl: 'modal-senha.html',
})
export class ModalSenhaComponent  implements OnInit {

  SenhaAntiga = '';
  NovaSenha = '';
  ConfirmarSenha = '';
  
  constructor(
    public dialogRef: MatDialogRef<ModalSenhaComponent>,
    private auth: AuthService,
    private util: UtilService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  updatePassword(form: FormGroup) {

    if (!form.valid) {
      return false;
    }

    if (this.NovaSenha !== this.ConfirmarSenha) {
      this.util.callAlert('Ops', 'Suas senhas devem se coincidir!', 'error');
      return false;
    }

    const values = form.value;

    this.auth.changePass(values).subscribe(res => {

      if (res['Status'] === 'success') {
        this.onNoClick();
        this.util.callAlert('Ok', res['Message'], 'success');
      } else {
        this.util.callAlert('OK!', res['Message'], 'error');

      }

    }, error => {
      this.util.callAlert('Ops!', 'Ocorreu um erro', 'error');
    });

  }
 
}