import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private http: HttpClient
  ) { }


  public getData(endpoint: string): Observable<any> {

    return this.http.get(`${environment.API}${endpoint}`);

  }

  public postData(endpoint: string, data: object): Observable<any> {

    return this.http.post(`${environment.API}${endpoint}`, data);

  }

}
