import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-autenticacao-certificado',
  templateUrl: './autenticacao-certificado.component.html',
  styleUrls: ['./autenticacao-certificado.component.scss']
})
export class AutenticacaoCertificadoComponent implements OnInit {

  sub: Subscription;
  loading = false;

  constructor( private util: UtilService
  ) { }

  ngOnInit() {
  }

  postAutenticidade(form: FormGroup) {

    const obj = form.value;
    this.loading = true;

    console.log(obj);

    this.util.postAutenticidade(obj)
    .subscribe(
      res => {
        this.util.callAlert('OK!', res['Message'], res['Status']);
        form.reset();
        this.loading = false;
      },
      err => {
        this.util.callAlert('Ops', err['error'].Message, err['error'].Status);
        this.loading = false;
      }
    );

  }

}
