import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import {  tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CursoService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getAllCursos() {

    const url = `${environment.API}cursos/getall`;

    return this.http.get(url);

  }

  getAllCategorias() {

    const url = `${environment.API}cursos/categorias/todas`;

    return this.http.get(url);

  }

  getCursoDetail(id: number) {

    const url = `${environment.API}cursos/getbyid/${id}`;

    return this.http.get(url);
  }

  searchCurso(curso: string) {

    const url = `${environment.API}cursos/buscar/${curso}`;

    return this.http.get(url);
  }

  getCursosUser() {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}cursos/obtertodos/${iduser}`;

    return this.http.get(url);


  }

  getCertificados() {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}certificados/${iduser}`;

    return this.http.get(url);


  }

  requestCertificado(idcurso, iduser) {

    const url = `${environment.API}certificados/solicitar/${idcurso}/${iduser}`;
    const data = {
      userid: iduser,
      cursoid: idcurso
    };

    return this.http.post(url, data)
    .pipe(
      tap(res => console.log(res))
    );

  }

  requestCertificadoByMatricula(idmatricula, iduser) {

    const url = `${environment.API}certificados/solicitar/${idmatricula}/${iduser}`;
    const data = {
      userid: iduser,
      matriculaId: idmatricula
    };

    return this.http.post(url, data)
    .pipe(
      tap(res => console.log(res))
    );

  }

  sendPergunta(data: object) {

    const url = `${environment.API}forum/enviarpergunta`;

    return this.http.post(url, data)
    .pipe(
      tap(res => console.log(res))
    );

  }

  sendAvaliation(data: object) {

    const url = `${environment.API}curso/avaliar`;

    return this.http.post(url, data)
    .pipe(
      tap(res => console.log(res))
    );

  }

  getForum() {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}forum/obterperguntas/${iduser}`;

    return this.http.get(url);
  }

  getCursosByCategoria(id: any) {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}cursos/categoria/${id}`;
    return this.http.get(url);
  }

  postQuestionary(data, matriculaId) {

    const url = `${environment.API}cursos/responderpesquisa/${matriculaId}`;

    return this.http.post(url, data)
    .pipe(
      tap(res => console.log(res))
    );

  }

}
