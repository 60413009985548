import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class UniversidadeCoorporativaService {

  constructor(
    private http: HttpClient
  ) { }

  getUniversidade() {
    const url = `${environment.API}universidade-coorporativa`;
    return this.http.get(url);
  }
}


