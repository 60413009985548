import { Component, Input, Output, EventEmitter, OnDestroy, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnDestroy, OnChanges {

  private subscription: any;
  currentPage = 1;
  pagination = [];
  @Input() TotalPages: any;
  @Output() emitPage = new EventEmitter(true);

  constructor(
    // private listaService: ListaService
  ) 
  {
    // this.subscription = this.listaService.myMethod$
    // .subscribe(data => {
    //   this.currentPage = data;
    // });
  }

  ngOnChanges() {

    this.createPages();
  }

  createPages() {

    this.pagination = [];

    for (let i=1; i<= this.TotalPages; i++) {

      this.pagination.push(i);

    }

  }

  setPage(value) {
    this.currentPage = value;
    this.emitPage.emit(value);
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

}
