import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSearch  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bar-search',
  templateUrl: './bar-search.component.html',
  styleUrls: ['./bar-search.component.scss']
})
export class BarSearchComponent implements OnInit {

  @Input() titlePlaceholder: string;
  @Output() sendSearch =  new EventEmitter(); 
  faSearch  = faSearch;
  
  constructor() { }

  ngOnInit() {
  }

}
