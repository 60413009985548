import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { UniversidadeCoorporativaComponent } from './pages/universidade-coorporativa/universidade-coorporativa.component'
import { SobreComponent } from './pages/sobre/sobre.component'
import { OrcamentoComponent } from './pages/orcamento/orcamento.component';
import { CarrinhoComponent } from './pages/carrinho/carrinho.component';
import { MeuPerfilComponent } from './pages/meu-perfil/meu-perfil.component';
import { AuthGuard } from './providers/auth.guard';
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { AutenticacaoCertificadoComponent } from './pages/autenticacao-certificado/autenticacao-certificado.component';
import { GenericPageComponent } from './pages/generic-page/generic-page.component';
import { PortifolioEmpresaComponent } from './pages/portifolio-empresa/portifolio-empresa.component';
import { PedidoFinalizadoComponent } from './pages/pedido-finalizado/pedido-finalizado.component';

const routes: Routes = [
  { path: '', loadChildren: './pages/cursos/cursos.module#CursosModule' },
  { path: 'livros', loadChildren: './pages/livros/livros.module#LivrosModule' },
  { path: 'contato', component: ContatoComponent },
  { path: 'autenticacao-certificado', component: AutenticacaoCertificadoComponent },
  { path: 'universidade-coorporativa', component: UniversidadeCoorporativaComponent },
  { path: 'politica', component: SobreComponent },
  { path: 'login', component: LoginComponent },
  { path: 'orcamento', component: OrcamentoComponent },
  { path: 'carrinho', component: CarrinhoComponent },
  { path: 'pedido-finalizado', component: PedidoFinalizadoComponent },
  // { path: 'qualidade-meio-ambiente', component: GenericPageComponent, 
  //   data: {
  //     title: 'Qualidade, Saúde, Segurança, Meio Ambiente, Responsabilidade social e Compliance',
  //     alias: 'qualidade-seguranca-meioambiente'
  //   }
  // },
  { path: 'portifolio-empresa', component: PortifolioEmpresaComponent },
  { path: 'promocoes', component: GenericPageComponent, 
    data: {
      title: 'Promoções',
      alias: 'promocoes'
    }
  },
  { path: 'formacoes-credenciais', component: GenericPageComponent, 
    data: {
      title: 'Formações Credenciais',
      alias: 'formacoes-e-credenciais'
    }
  },
  { path: 'politica-privacidade', component: GenericPageComponent, 
    data: {
      title: 'Política privacidade e segurança da informação',
      alias: 'politica-privacidade'
    }
  },
  // { path: 'politica-privacidade', component: PoliticaPrivacidadeComponent },
  { path: 'meu-perfil', component: MeuPerfilComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'cursos'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
