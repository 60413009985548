import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { faAngleDown, faCalendar, faCheckCircle, faClock, faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card-historico',
  templateUrl: './card-historico.component.html',
  styleUrls: ['./card-historico.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardHistoricoComponent implements OnInit {

  @Input() titulo: string;
  @Input() data: object[];
  @Input() tipo: string;

  faAngleDown = faAngleDown;
  faCalendar = faCalendar;
  faCheckCircle = faCheckCircle;
  faClock = faClock;
  faEdit = faEdit;
  faTimesCircle = faTimesCircle;
  
  constructor() { }

  ngOnInit() {
  }

}
