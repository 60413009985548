import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import { AuthService } from '../../providers/auth.service';
import { CrudService } from 'src/app/providers/crud.service';
import { Router } from '@angular/router';

import { MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'forgoten-pass',
  templateUrl: 'forgoten-pass.html',
  styleUrls: ['./login.component.scss']
})
export class DialogForgoten {

  email_verificado: string;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<DialogForgoten>,
    private auth: AuthService,
    private util: UtilService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  postForgoten(form: FormGroup) {

    if (!form.valid) { return false; }

    const values = form.value;
    this.loading = true;

    this.auth.sendEmail(values).subscribe(
      res => {
        this.onNoClick();
        this.util.callAlert('OK!', res['Message'], res['Status']);
        this.loading = false;
      },
      err => {
        this.util.callAlert('Ops', err['error'].Message, err['error'].Status);
        this.loading = false;
      }
    );

  }

}


export class User {

  LembrarDeMim: boolean;
  usuario: string;
  senha: string;

}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User = new User();
  loading = false;

  constructor(
    private auth: AuthService,
    private util: UtilService,
    private crud: CrudService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  openDialog(): void {

    const dialogRef = this.dialog.open(DialogForgoten, {
      width: '500px'
    });

    dialogRef.afterClosed()
    .subscribe(result => {
      
    });

  }

  postLogin(form: FormGroup) {

    if (!form.valid) { return false; }

    this.loading = true;

    let credentials = { 
      LembrarDeMim: true,
      ...form['value'] 
    };

    this.crud
    .postData('usuarios/login', credentials)
    .subscribe(res => {
      
      if (res['Status'] !== 'error') {
        this.auth.afterLogin(res['Data']);
        this.loading = false;
        this.router.navigate(['cursos']);
        this.util.callAlert('Olá', 'Seja bem vindo!', 'success');

      } else {
        this.util.callAlert('Ops!', res.error['Message'], 'error');
        this.loading = false;
      }



    }, error => {
      this.util.callAlert('Ops!', error.error['Message'], 'error');
      this.loading = false;
    });

  }

}
