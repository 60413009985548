import { Directive, ElementRef, ViewContainerRef, Input, TemplateRef, OnInit } from '@angular/core';
import { AuthService } from '../providers/auth.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnInit {

  private currentUser;

  constructor(
    private auth: AuthService,
    private element: ElementRef,
    private template: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  @Input()
  set appPermission(val) {
    if (val) {
      this.viewContainer.createEmbeddedView(this.template);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnInit() {}

}
