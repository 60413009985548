import { Component, OnInit, Input } from '@angular/core';
import { faAngleDown, faCalendar, faCheckCircle, faClock, faEdit, faTimesCircle, faPrint, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-trataments',
  templateUrl: './trataments.component.html',
  styleUrls: ['./trataments.component.scss']
})
export class TratamentsComponent implements OnInit {

  @Input() titulo: string;
  @Input() data: object[];
  @Input() tipo: string;

  faAngleDown = faAngleDown;
  faCalendar = faCalendar;
  faCheckCircle = faCheckCircle;
  faClock = faClock;
  faEdit = faEdit;
  faPrint = faPrint;
  faTimesCircle = faTimesCircle;
  faPlusCircle  = faPlusCircle;

  constructor() { }

  ngOnInit() {
  }

}
