import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

interface PayloadPage {
  Imagens: {Imagem: string, Legenda?: string}[],
  Texto: string;
  Pdfs: string[]
}

@Component({
  selector: 'app-generic-page',
  templateUrl: './generic-page.component.html',
  styleUrls: ['./generic-page.component.scss']
})
export class GenericPageComponent implements OnInit {

  faFilePdf = faFilePdf;
  titlePage = '';
  alias = '';
  data: PayloadPage = null;
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, autoplay: true};

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) { 

    this.titlePage = this.route.snapshot.data['title']; 
    this.alias = this.route.snapshot.data['alias'];

  }

  ngOnInit() {
    this.requestByPage();
  }

  private requestByPage() {

    if (this.alias) {
      this.http.get(`${environment.API}${this.alias}`)
      .subscribe((response: { Data: PayloadPage }) => {
        this.data = response.Data;
      });
    }

  }

}
