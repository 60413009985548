import { Component } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { faTimesCircle, faEdit  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss']
})
export class AgreementsComponent {

  faEdit = faEdit;
  faTimesCircle = faTimesCircle;
}
