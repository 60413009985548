import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { BehaviorSubject } from 'rxjs';
import { Http } from '@angular/http';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  public listCart = [];
  private totalItens = new BehaviorSubject<any>({});

  constructor(
    private util: UtilService,
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getList() {
    return JSON.parse(sessionStorage.getItem('lista')) || [];
  }

  getListById() {

    const list = this.getList();

    return list.map(value => {

      return value.Id;

    });


  }

  updateTotal() {
    
    const total = this.getTotalElements();
    this.totalItens.next(total);
    return this.totalItens.asObservable();
  }

  addCart(item: object) {

    this.listCart = this.getList();

    for (const i of this.listCart) {
      if (i.Id === item['Id']) {
        this.util.callAlert('Ops', 'Este item já consta em seu carrinho', 'error');
        return false;
      }
    }
    this.listCart.push(item);
    sessionStorage.setItem('lista', JSON.stringify(this.listCart));
    this.util.callAlert('Ok', 'Produto adicionado ao seu carrinho!', 'success');
    this.updateTotal();

  }

  finishPaymentFree(item: object) {
    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}pagamentos/finalizarpagamentofree?userId=${iduser}&itemId=${item['Id']}&tipo=${item['Tipo']}`;
    return this.http.get(url);
  }

  removeItem(index: number) {
    this.listCart = this.getList();
    this.listCart.splice(index, 1);
    sessionStorage.setItem('lista', JSON.stringify(this.listCart));
    this.util.callAlert('Ok', 'Curso removido do seu carrinho!', 'success');
    this.updateTotal();
  }

  getTotalElements() {
    this.listCart = this.getList();
    return this.listCart.length;
  }

  postOrcamento(data: object) {
    const url = `${environment.API}orcamentoempresa/solicitar`;
    return this.http.post(url, data);
  }

}
