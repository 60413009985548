import { QuestionarioCursoComponent } from './cursos/questionario-curso/questionario-curso.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { MatMenuModule, MatSelectModule, MatDialogModule, MatRadioModule, MatCheckboxModule, MatDatepickerModule, MatInputModule, MatTabsModule, MatNativeDateModule, MatExpansionModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';

import { ListaCarrinhoComponent } from './lista-carrinho/lista-carrinho.component';
import { MainHeaderComponent, CadastroComponent } from './main-header/main-header.component';
import { CardHistoricoComponent } from './card-historico/card-historico.component';
import { PaginationComponent } from './pagination/pagination.component';
import { BarSearchComponent } from './bar-search/bar-search.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { TratamentsComponent } from './trataments/trataments.component';
import { CursosComponent, ModalAvaliacaoComponent } from './cursos/cursos.component';
import { MainFooterComponent } from './main-footer/main-footer.component';
import { StarRatingComponent } from './star-rating/star-rating.component';


export const COMPONENTS = [

  MainHeaderComponent,
  CardHistoricoComponent,
  PaginationComponent,
  BarSearchComponent,
  AgreementsComponent,
  TratamentsComponent,
  CursosComponent,
  ModalAvaliacaoComponent,
  CadastroComponent,
  MainFooterComponent,
  ListaCarrinhoComponent,
  StarRatingComponent,
  QuestionarioCursoComponent,
  
];

export const MODULES = [
  CommonModule,
  FontAwesomeModule,
  RouterModule,
  MatMenuModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  FormsModule,
  ReactiveFormsModule,
  MatFormFieldModule,
  FormsModule,
  FontAwesomeModule,
  FormsModule,
  FontAwesomeModule,
  MatSelectModule,
  MatDialogModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatIconModule,
  MatInputModule,
  MatTabsModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatNativeDateModule,
  HttpClientModule,
  NgxMaskModule
];

@NgModule({
  imports: [
    ...MODULES
  ],
  exports: [
    ... COMPONENTS,
    ... MODULES
  ],
  entryComponents: [
    ModalAvaliacaoComponent,
    ListaCarrinhoComponent,
    CadastroComponent,
    QuestionarioCursoComponent
  ],
  declarations: [
    ...COMPONENTS
  ]
})
export class ComponentsModule { }
