import { Component, OnInit } from '@angular/core';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { UniversidadeCoorporativaService } from '../../providers/universidade-coorporativa.service';
import { Subscription } from 'rxjs';
import { Universidade } from 'src/app/models/universidade';

@Component({
  selector: 'app-universidade-coorporativa',
  templateUrl: './universidade-coorporativa.component.html',
  styleUrls: ['./universidade-coorporativa.component.scss']
})
export class UniversidadeCoorporativaComponent implements OnInit {

  faFileExcel = faFileExcel;
  sub: Subscription;
  id: number;
  universidade: Universidade = new Universidade();

  constructor(
    private UniversidadeService: UniversidadeCoorporativaService,
  ) { }

  ngOnInit() {
    this.loadingText();
  }

  private loadingText() {
    this.sub = this.UniversidadeService
    .getUniversidade()
      .subscribe(res =>  {
        this.universidade = res['Data'];
      }
    );
  }
}
