import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private http: HttpClient
  ) {}

  public callAlert(title, msg, type) {
    return swal({
      title: title,
      text: msg,
      type: type,
      showConfirmButton: false,
      timer: 10000
    });

  }

  public callAlert2(title, msg, type) {
    return swal({
      title: title,
      text: msg,
      type: type,
      showConfirmButton: false,
      timer: 7000
    });
  }

  public solicitarSegundaViaCertificado(idmatricula) {
     swal({
        title:  'SEGUNDA VIA',
        text: 'O valor para emissão da segunda via é de R$ 15,00. Deseja continuar?',
        type: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        timer: 7000
      }).then((result) => {
        if (result.value == true) {

          const url = `${environment.API}certificados/solicitar/segunda-via/${idmatricula}`;
          const data = {
            matriculaid: idmatricula
          };

          this.postCertificadoSegundaVia(idmatricula)
            .subscribe(
              res => {
                swal({
                  title: 'SEGUNDA VIA',
                  type: 'info',
                  html: '<p>Enviar o comprovante para o email gerencia@portalmce.com ou whatsApp (24) 99998-1224.<br/><br/><strong>Dados da Conta</strong><br/>Banco: Sicoob Nº 756 <br/><br/>Agencia: 3260<br/>Conta Corrente: 45834-1<br/>CNPJ: 19.900.242/0001-60<br/>PIX: 19900242000160<br/></p>',
                  showConfirmButton: true,
                  showCancelButton: true,
                  cancelButtonText: 'Fechar',
                  timer: 500000
                });
              },
              err => {
                swal('Falha!','Houve um erro ao solicitar a segunda via de certificado, entre em contato através do telefone (24) 99998-1224.','error')
              }
            );
        }
      })
  }

  postCertificadoSegundaVia(idmatricula) {
    const url = `${environment.API}certificados/solicitar/segunda-via/${idmatricula}`;
          const data = {
            matriculaid: idmatricula
          };

    return this.http.post(url, data);
  }


  postContact(form: FormGroup) {

    const url = `${environment.API}contato/enviarmensagem`;
    form['Resposta'] = '';
    form['Data'] = new Date;
    form['DataResposta'] = new Date;
    form['Status'] = 0;

    return this.http.post(url, form);
  }

  postAutenticidade(form: FormGroup) {
    const url = `${environment.API}autenticidade`;
    return this.http.post(url, form);
  }

  postPagamento(data: string[], userid: number) {
    const url = `${environment.API}pagamentos/realizarpagamento/${userid}`;
    return this.http.post(url, data);
  }

  postPagamentoFinalizado(nomeItens: string[], userid: number, codtransacao : string) {
    console.log(codtransacao);
    console.log(codtransacao);
    console.log(codtransacao);


    const url = `${environment.API}pagamentos/pagamentofinalizado?userid=${userid}&codigotransacao=${codtransacao}`;
    return this.http.post(url, nomeItens);
  }
}

export class ModelPagamentoFinalizado {
  transacaoid: string;
  cursoids: number[];
}
