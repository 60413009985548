import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent, DialogForgoten } from './pages/login/login.component';
import { ContatoComponent } from './pages/contato/contato.component';
import { ComponentsModule } from './components/components.module';
import { OrcamentoComponent } from './pages/orcamento/orcamento.component';
import { AuthService } from './providers/auth.service';
import { AuthGuard } from './providers/auth.guard';
import { CompraService } from './providers/compra.service';
import { CarrinhoComponent } from './pages/carrinho/carrinho.component';
import { MeuPerfilComponent, ModalSenhaComponent } from './pages/meu-perfil/meu-perfil.component';
import { PoliticaPrivacidadeComponent } from './pages/politica-privacidade/politica-privacidade.component';
import { NgxMaskModule } from 'ngx-mask';
import { CrudService } from './providers/crud.service';
import { PermissionDirective } from './directives/permission.directive';
import { MinutesecondsPipe } from './pipes/minuteseconds.pipe';
import { HttpModule } from '@angular/http';
import { UniversidadeCoorporativaComponent } from './pages/universidade-coorporativa/universidade-coorporativa.component';
import { AutenticacaoCertificadoComponent } from './pages/autenticacao-certificado/autenticacao-certificado.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { GenericPageComponent } from './pages/generic-page/generic-page.component';
import { PedidoFinalizadoComponent } from './pages/pedido-finalizado/pedido-finalizado.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PortifolioEmpresaComponent } from './pages/portifolio-empresa/portifolio-empresa.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ContatoComponent,
    OrcamentoComponent,
    CarrinhoComponent,
    MeuPerfilComponent,
    ModalSenhaComponent,
    DialogForgoten,
    GenericPageComponent,
    PoliticaPrivacidadeComponent,
    UniversidadeCoorporativaComponent,
    SobreComponent,
    AutenticacaoCertificadoComponent,
    PortifolioEmpresaComponent,
    PedidoFinalizadoComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SlickCarouselModule,
    HttpModule,
    ComponentsModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot({
      dropSpecialCharacters: false
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    AuthService,
    CompraService,
    PermissionDirective,
    MinutesecondsPipe,
    CrudService,
    AuthGuard
  ],
  entryComponents: [ ModalSenhaComponent, DialogForgoten ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
