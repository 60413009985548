import { Component, OnInit, Output } from '@angular/core';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { CompraService } from '../../providers/compra.service';
import { EventEmitter } from 'protractor';
import { UtilService } from '../../providers/util.service';
@Component({
  selector: 'app-lista-carrinho',
  templateUrl: './lista-carrinho.component.html',
  styleUrls: ['./lista-carrinho.component.scss']
})
export class ListaCarrinhoComponent implements OnInit {

  faShoppingCart = faShoppingCart;
  listCart = [];
  total: number;
  quantityCart: number;

  constructor(
    private compraService: CompraService,
    private util: UtilService
  ) { }

  ngOnInit() {
    this.loadingCart();
  }

  loadingCart() {
    this.listCart = this.compraService.getList();
    let soma = 0;
    for (const i of this.listCart) {
      soma += i['Valor'];
    }
    this.total = soma;
    this.quantityCart = this.compraService.getTotalElements();
  }
  formatValue(number){
    return number.toFixed(2).toString().replace('.',',')
  }

  removeItem(index) {
    this.compraService.removeItem(index);
    this.loadingCart();
  }
}
