import { Component, OnInit } from '@angular/core';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { SobreService } from '../../providers/sobre.service';
import { Subscription } from 'rxjs';
import { Sobre } from 'src/app/models/sobre';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  faFileExcel = faFileExcel;
  faFilePdf = faFilePdf;
  sub: Subscription;
  id: number;
  sobre: Sobre = new Sobre();

  constructor(
    private SobreService: SobreService,
  ) { }

  ngOnInit() {
    this.loadingText();
  }

  private loadingText() {
    this.sub = this.SobreService
    .getSobre()
      .subscribe(res =>  {
        this.sobre = res['Data'];
      }
    );
  }
}
