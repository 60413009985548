import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  sub: Subscription;
  loading = false;

  constructor(
    private util: UtilService
  ) { }

  ngOnInit() {}

  postContact(form: FormGroup) {

    const obj = form.value;

    this.loading = true;

    this.util.postContact(obj)
    .subscribe(
      res => {
        this.util.callAlert('OK!', res['Message'], res['Status']);
        form.reset();
        this.loading = false;
      },
      err => {
        this.util.callAlert('Ops', err['error'].Message, err['error'].Status);
        this.loading = false;
      }
    );

  }


}
