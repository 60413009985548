import { Component, OnInit, OnDestroy, Inject, AfterViewChecked } from '@angular/core';
import { faShoppingCart, faAngleDown, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../providers/auth.service';
import { Subscription } from 'rxjs';
import { CompraService } from '../../providers/compra.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import { Http } from '@angular/http';

@Component({
  selector: 'modal-cadastro',
  templateUrl: 'modal-cadastro.html'
})
export class CadastroComponent {

  constructor(
    public dialogRef: MatDialogRef<CadastroComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService,
    private util: UtilService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  postCadastro(form: FormGroup) {
    const values = form.value;
    this.auth.createCadastro(values)
    .subscribe(
      (data: any) => {
        this.util.callAlert('Ok!', data.Message, 'success');
        form.reset();
        this.onNoClick();
      },
      err =>  this.util.callAlert('Ops!', 'Este e-mail já está cadastrado!', 'error')
    );
  }

}

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnDestroy, OnInit {

  faShoppingCart = faShoppingCart;
  faAngleDown = faAngleDown;
  faLogout = faSignOutAlt;
  faBars = faBars;
  openMenu = false;
  logged: boolean;
  sub: Subscription;
  countCart: number;
  user: any = {};

  menuLinks = [
    { title: 'Cursos', link: '/cursos' },
    { title: 'Cursos para Empresas', link: '/orcamento' },
    { title: 'Contato', link: '/contato' },
    { title: 'Autenticidade do Certificado', link: '/autenticacao-certificado' },
  ]

  constructor(
    private auth: AuthService,
    private compra: CompraService,
    public dialog: MatDialog
  ) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(CadastroComponent, {
      width: '380px',
      panelClass: 'modal'
    });
  }

  ngOnInit() {

    this.auth.userAuth.subscribe(value => {
      this.user = value;
    });

    this.sub = this.auth.isLogged.subscribe(res => {
      this.logged = res;
    });

    this.sub = this.compra.updateTotal()
    .subscribe(data => {
      this.countCart = data;
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  logout() {
    this.auth.logout();
  }

}
