import { QuestionarioCursoComponent } from './questionario-curso/questionario-curso.component';
import { TypeList } from './../../models/lista';
import { Component, OnInit, Inject, ViewEncapsulation, Input } from '@angular/core';
import { faTimesCircle, faEdit, faShoppingCart, faPlayCircle  } from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CompraService } from '../../providers/compra.service';
import { AuthService } from '../../providers/auth.service';
import { CursoService } from '../../providers/curso.service';
import { UtilService } from '../../providers/util.service';
import { FormGroup } from '@angular/forms';
import { Curso } from 'src/app/models/curso';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'modal-avalia',
  templateUrl: 'modal-avalia.html',
})
export class ModalAvaliacaoComponent  implements OnInit {

  enabled = false;
  rating = 0;
  starCount = 5;
  
  constructor(
    public dialogRef: MatDialogRef<ModalAvaliacaoComponent>,
    private cursoService: CursoService,
    private auth: AuthService,
    private util: UtilService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  updateRating(event) {
    this.rating = event;
  }

  PostAvaliation(form: FormGroup) {
    if (!form.valid) {
      return false;
    }

    const values = form.value;
    values['Avaliacao'] = this.rating;
    values['MatriculaId'] = this.auth.getUserLogged();

    this.cursoService.sendAvaliation(values)
    .subscribe(data => {
      this.util.callAlert('OK!', data['Message'], data['Status']);
      this.onNoClick();
    });

  }
 
}

@Component({
  selector: 'list-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CursosComponent implements OnInit {

  faTimesCircle = faTimesCircle;
  faEdit = faEdit;
  faPlayCircle = faPlayCircle;
  faShoppingCart = faShoppingCart;
  typeList = TypeList;
  @Input() type: TypeList;
  @Input() tipo: boolean;
  @Input() data: Curso[];

  constructor(
    private dialog: MatDialog,
    private compraService: CompraService,
    private auth: AuthService,
    private cursoService: CursoService,
    private utilService: UtilService,
    private router: Router
  ) {}

  ngOnInit() {

  }

  openAvaliacao() {
    const dialogRef = this.dialog.open(ModalAvaliacaoComponent, { panelClass: 'dialog', width: '570px', data: { } });
  }

  goToDetail(item) {

    const route = this.type == TypeList.Livro ? 'livros/' : '/cursos/'

    this.router.navigateByUrl(`${route}${item.Id}`);
  }

  addCart(item) {
    this.compraService.addCart(item);
  }

  finishPaymentFree(item) {

    const userId = this.auth.getUserLogged();
    console.log(userId);
    
    if(userId == null){
      console.log("passou aqui");
      this.utilService.callAlert('Ops', "É necessário realizar login para finalizar a compra", "error");
    }
    else {
      this.compraService.finishPaymentFree(item)
      .subscribe(
        data => {
            this.utilService.callAlert('OK', data['Message'], data['Status']);
        },
        err => {
            this.utilService.callAlert('Ops', err['error'].Message, err['error'].Status);
        });
    }
  }

  requestCertificate(cursoId) {
    const userId = this.auth.getUserLogged();
    this.cursoService.requestCertificado(cursoId, userId)
    .subscribe(
      data => {
        this.utilService.callAlert('OK', data['Message'], data['Status']);
    });
  }

  requestCertificateByMatricula(matriculaId) {
    const userId = this.auth.getUserLogged();
    this.cursoService.requestCertificadoByMatricula(matriculaId, userId)
    .subscribe(
      data => {
        this.utilService.callAlert('OK', data['Message'], data['Status']);
    });
  }


  requestCertificateSegundaVia(matriculaId) {
    
    this.utilService.solicitarSegundaViaCertificado(matriculaId);

  }
  
  formatValue(number){
    return number.toFixed(2).toString().replace('.',',')
  }

  answerQuestionary(item) {

    const dialogRef = this.dialog.open(QuestionarioCursoComponent, 
      { panelClass: 'dialog', width: '600px', data: { }
    });

    dialogRef.afterClosed().subscribe(payload => {

      if (payload) {

        this.cursoService.postQuestionary(payload, item.MatriculaId)
        .subscribe(response => {

          this.utilService.callAlert('Obrigado!', 'Sua opnião é importante para nós!', 'success');
          item.PesquisaRespondida = true;

        }, error => {
          this.utilService.callAlert('Ops!', 'Não foi possível registrar sua pesquisa.', 'error');
        });
      } 
    });
  }
}
