import { Component, OnInit } from '@angular/core';
import { faFileExcel, faFilePdf, faPlay } from '@fortawesome/free-solid-svg-icons';
import { SobreService } from '../../providers/sobre.service';
import { Subscription } from 'rxjs';
import { Portifolio } from 'src/app/models/portifolio';

@Component({
  selector: 'app-portifolio-empresa',
  templateUrl: './portifolio-empresa.component.html',
  styleUrls: ['./portifolio-empresa.component.scss']
})
export class PortifolioEmpresaComponent implements OnInit {

  faFileExcel = faFileExcel;
  faFilePdf = faFilePdf;
  faPlay = faPlay;
  sub: Subscription;
  id: number;
  portifolio: Portifolio = new Portifolio();

  constructor(
    private SobreService: SobreService,
  ) { }

  ngOnInit() {
    this.loadingText();
  }

  private loadingText() {
    this.sub = this.SobreService
    .getPortifolio()
      .subscribe(res =>  {
        console.log(res['Data']);
        this.portifolio = res['Data'];
      }
    );
  }

}
