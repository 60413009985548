import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../providers/util.service';
import { CompraService } from 'src/app/providers/compra.service';
import {Router} from "@angular/router"

declare var pagseguroteste: any;

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.scss']
})

export class CarrinhoComponent implements OnInit {

  
  constructor(
    private util: UtilService,
    private compra: CompraService,
    private router: Router
  ) {}

  ngOnInit() {
  
  }

  
  public realizarPagamento()
  {
    let userid : number;
    userid = Number(this.getUserLogged());

    if(userid != null && userid != 0)
    {
      const ids_Compras = this.compra.getList().map(item => {
        return item.Id;
      });

      const nomes_compras = this.compra.getList().map(item => {
        return item.Nome;
      });

      let cursos : number[]
      cursos = ids_Compras;

      let items : string[]
      items = nomes_compras;
      
      this.util.postPagamento(items, userid)
      .subscribe(
        data => {
                 sessionStorage.removeItem('lista');
                 window.location.href = "https://ead.portalmce.com/pedido-finalizado"

        },
        err => {
          this.util.callAlert('Ops', err['error'].Message, err['error'].Status);
        }
      );
    }
    else
    {
      this.util.callAlert('Atenção', 'Para realizar o pagamento dos cursos entre em contato: Email: contato@masterintelligence.net.br - WhatsApp: 24- 99998-1224' , 'error');
    }
  }


  public pagamentoRealizado(nomeItens: string[], userid: number, codtransacao: string)
  {
    this.util.postPagamentoFinalizado(nomeItens, userid, codtransacao)
    .subscribe(
      data => {
         if(data['Data'] === 'OK')
         {
            this.util.callAlert('Compra finalizada', 'Compra realizada com sucesso, o curso já encontra disponível','success');
            this.router.navigate(['/meus-cursos'])
         }
      },
      err => {
        this.util.callAlert('Ops', err['error'].Message, err['error'].Status);
      }
    );
  }

  getUserLogged(): object {
    return JSON.parse(sessionStorage.getItem('user'));
  }
}
