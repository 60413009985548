import { Component, OnInit, OnDestroy } from '@angular/core';
import { CompraService } from '../../providers/compra.service';
import { FormGroup } from '@angular/forms';
import { UtilService } from '../../providers/util.service';
import { CursoService } from 'src/app/providers/curso.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html',
  styleUrls: ['./orcamento.component.scss']
})
export class OrcamentoComponent implements OnInit, OnDestroy {

  listCart = [];
  listCursos = [];
  loading = false;
  IdsCurso = [];
  sub: Subscription;

  constructor(
    private compraService: CompraService,
    private util: UtilService,
    private cursoService: CursoService
  ) { }

  ngOnInit() {
    this.sub = this.cursoService.getAllCursos()
    .subscribe((data: any) => {
      this.listCursos = data['cursos'];
    });
  }


  postOrcamento(form: FormGroup) {

    if (!form.valid) {
      return false;
    }
    this.loading = true;
    const values = form.value;
 
    this.compraService.postOrcamento(values).subscribe(
      data => {
        this.util.callAlert('OK!', data['Message'], data['Status']);
        this.loading = false;
      },
      err => {
        this.util.callAlert('Ops!', 'Ocorreu um erro no envio de seu orçamento!', 'error');
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
