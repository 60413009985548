import { questions } from './questoes';

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../../../providers/auth.service';
import { CursoService } from '../../../providers/curso.service';
import { UtilService } from '../../../providers/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'questionario-curso',
  templateUrl: 'questionario-curso.html',
})
export class QuestionarioCursoComponent  implements OnInit {

  questions = questions;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private cursoService: CursoService,
    private auth: AuthService,
    private util: UtilService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    this.form = this.fb.group({
      'Resposta1': ['', Validators.required],
      'Resposta2': ['', Validators.required],
      'Resposta3': ['', Validators.required],
      'Resposta4': [''],
    });

  }
}

