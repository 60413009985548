import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { UtilService } from './util.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CrudService } from './crud.service';

@Injectable()
export class AuthService {

  private currentUserSubject = new BehaviorSubject<any>({});
  userAuth = new Subject();

  constructor(
    private http: HttpClient,
    private router: Router,
    private util: UtilService,
    private crud: CrudService
  ) {}

  afterLogin(data: object) {

    this.saveSession(data);
    this.saveUser();
    this.currentUserSubject.next(true);

  }

  sendEmail(values: object) {

    const url = `${environment.API}usuarios/recuperarsenha?email=${values['email']}`;

    return this.http.post(url, values);

  }

  changePass(values: object) {

    values['UserId'] = this.getUserLogged();

    const url = `${environment.API}usuarios/alterarsenha`;

    return this.http.post(url, values);

  }

  saveSession(value) {
    sessionStorage.setItem('user', value);
  }

  getDataUser(): object {
    return JSON.parse(sessionStorage.getItem('datauser'));
  }

  getUserLogged(): object {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  get isLogged() {

    if (!this.getUserLogged()) {
      this.currentUserSubject.next(false);
    } else {
      this.currentUserSubject.next(true);
    }
    return this.currentUserSubject.asObservable();
  }

  logout() {

    this.crud.postData(`usuarios/logoff?userId=${Number(this.getUserLogged())}`, {}).subscribe(res => {
      
    });

    this.currentUserSubject.next(false);
    sessionStorage.removeItem('user');
    this.router.navigate(['']);


  }

  createCadastro(data) {
    const url = `${environment.API}usuarios/cadastrar`;

    return this.http.post(url, data).pipe(
      tap(res => console.log(res))
    );

  }

  saveUser() {

    let user = {};
    const iduser = this.getUserLogged();
    const url = `${environment.API}usuarios/meuperfil/${iduser}`;

    this.http.get(url).subscribe(res => {
      user = res['Data'];
      this.userAuth.next(user);
      sessionStorage.setItem('datauser', JSON.stringify(user));
    });

  }

  set theItem(value) {
    this.userAuth.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('datauser', value);
  }

  get theItem() {
    return JSON.parse(sessionStorage.getItem('datauser'));
  }

  updateUser(data: object) {

    const iduser = this.getUserLogged();
    const url = `${environment.API}usuarios/meuperfil/${iduser}`;

    return this.http.post(url, data);

  }

}