
const optionsDefault = ['Ótimo', 'Bom', 'Ruim', 'Péssimo'];

const optionSecondary = [
    'Nada atenciosos e prestativos',
    'Pouco atenciosos e prestativos',
    'Indiferente',
    'Atenciosos e prestativos',
    'Extremamente atenciosos e prestativos'
]

export const questions: {title: string, options: string[]}[] = [
    {
        title: 'Seu grau de satisfação com a velocidade do atendimento da Empresa:',
        options: optionsDefault
    },
    {
        title: 'Como você avalia a QUALIDADE dos nossos produtos/serviços?',
        options: optionsDefault
    },
    {
        title: 'Como você avalia nosso atendimento, com relação ao esclarecimento  de dúvidas e resolução de problemas?',
        options: optionSecondary
    }
]